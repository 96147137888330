exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aboutus-js": () => import("./../../../src/pages/aboutus.js" /* webpackChunkName: "component---src-pages-aboutus-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-whatwedo-js": () => import("./../../../src/pages/whatwedo.js" /* webpackChunkName: "component---src-pages-whatwedo-js" */),
  "component---src-pages-whoweare-js": () => import("./../../../src/pages/whoweare.js" /* webpackChunkName: "component---src-pages-whoweare-js" */),
  "component---src-pages-whoweserve-js": () => import("./../../../src/pages/whoweserve.js" /* webpackChunkName: "component---src-pages-whoweserve-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-case-template-js": () => import("./../../../src/templates/caseTemplate.js" /* webpackChunkName: "component---src-templates-case-template-js" */),
  "component---src-templates-contact-us-js": () => import("./../../../src/templates/contactUs.js" /* webpackChunkName: "component---src-templates-contact-us-js" */),
  "component---src-templates-core-service-js": () => import("./../../../src/templates/coreService.js" /* webpackChunkName: "component---src-templates-core-service-js" */),
  "component---src-templates-news-post-js": () => import("./../../../src/templates/newsPost.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/newsTemplate.js" /* webpackChunkName: "component---src-templates-news-template-js" */),
  "component---src-templates-tech-talk-js": () => import("./../../../src/templates/techTalk.js" /* webpackChunkName: "component---src-templates-tech-talk-js" */)
}

